import React, { useState, useContext, useEffect } from 'react';
import {
  CssBaseline,
  Container,
  Grid,
} from '@material-ui/core';
import InfoCard from './parts/infoCard'
import { ImageLoading } from 'components/loading';

//styles
import useStyles from './styles'

//Context
import { ParticipantesContext } from '../participantes/context';
import { APIContext } from 'context';

const ParticipanteView = ({id}) => {

  const classes = useStyles();
  const [data, setData] = useState(null)
  const { getParticipante } = useContext(ParticipantesContext);
  const { promocionActual, getCiudad } = useContext(APIContext);
  
 useEffect(() => {
   iniciar()
  }, []); 

  async function iniciar(){
    const x = await getParticipante(promocionActual.id, id)
    const y = await getCiudad(x.ciu_cli)
    let z = x
    z.ciu_nombre = y.ciu_nombre
    setData(z)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <main className={classes.content}>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>
              
              {data ? (
                <InfoCard data={data} id={id}/>
              ) : (
                <ImageLoading />
              )}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default ParticipanteView;