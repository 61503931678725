import { createMuiTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createMuiTheme({
    palette: {
        // type: 'dark',
        primary: {
          light: '#b55799',
          main: '#802c72',
          dark: '#530040',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#5fe2fc',
          main: '#00b0c9',
          dark: '#008098',
          contrastText: '#ffffff',
        },
        text: {
          primary: "#484040",
          secondary: "#01426a"
        },
      },
      overrides: {
        MuiContainer:{
          maxWidthLg:{
            maxWidth:'1100px!important',
          },
        },
        MuiInputBase: {
          root:{
            color: '#1f3d4b !important',
          },
        },   
        MuiTab: {
          root: {
            "&.MuiTab-root": {
              opacity: 1,
              border: 0,
              "&:hover": {
                border: 0,
              },
            },
            "&.Mui-selected": {
              border: 0,
            }
          }
        },
        MuiDropzoneArea: {
          root: {
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center'
          },
          textContainer: {
            maxWidth: '300px'
          }
        },
    
        MuiDropzonePreviewList: {
          image: {
            maxHeight: '200px',
          }
        },
      },
    
      typography: {
        fontFamily: 'Gotham Rounded, Agenda, Arial',
        body1: {
          fontSize:  '0.875rem'
        },
      },
});

export default theme;