import React from "react";
import { useState, useContext} from 'react';
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import Linkx from '@material-ui/core/Link';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { APIContext } from '../context';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Linkx component={Link} color="inherit" to="/">
        WanaBana
        </Linkx>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    //backgroundImage: 'url(https://source.unsplash.com/collection/430968)',
    //backgroundImage: `url(${LoginFondo})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.primary.main,
      //theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  //});
}));

export default function Sign_In(props) {
  const { signIn,
          sendPasswordEmail ,
          dialogAlertContent,
          setDialogAlertContent, 
          setDialogAlertOpen, 
  } = useContext(APIContext);

  const classes = useStyles();
  const [autenticacion, setAutenticacion] = useState({
    username: "",
    password: ""
  });
  const [email, setEmail] = useState("");

  const [viewForget, setViewForget] = useState(false);

  const history = useHistory();

  function verificar_inputs() {
    let error = false;

    if (autenticacion.username === '' || autenticacion.password === '') {
      error = true
    }

    if (error) {
      setDialogAlertOpen(true)
      setDialogAlertContent({
        ...dialogAlertContent,
        'mensaje': 'Ingrese el Usuario y Contraseña para continuar',
        'titulo': 'Error al ingresar',
      })
      return false;
    } else {
      return true;
    }
  }

  function verificar_email() {
    let error = false;

    if (email === '') {
      error = true
    }

    if (error) {
      setDialogAlertOpen(true)
      setDialogAlertContent({
        ...dialogAlertContent,
        'mensaje': 'Ingrese el correo electrónico para recibir su contraseña',
        'titulo': 'Restablecer Contraseña',
      })
      return false;
    } else {
      return true;
    }
  }

  const updateField = e => {
    setAutenticacion({
      ...autenticacion,
      [e.target.name]: e.target.value
    });
  };

  const updateEmailField = e => {
    setEmail(e.target.value);
  };

  async function SignIn(e) {
    e.preventDefault();
   if (verificar_inputs()) {
        const res = await signIn(autenticacion);
        if(res){
          history.push('/admin');
        }else{
          return
        }
    } 
    /* if (verificar_inputs()) {
      await app
      .auth()
      .signInWithEmailAndPassword(autenticacion.username, autenticacion.password)
      .then(result => {
        console.log(result);
        history.push('/admin');
      })
      .catch(error => {
        console.log(error.message);
        //  seterror(error.message)
        return
      });
    } */
  }

  async function SendPass(e) {
    e.preventDefault();
    if (verificar_email()) {
        await sendPasswordEmail(email);
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
        {!viewForget 
        ? 
        <>
          <Avatar className={classes.avatar}>
            <PermIdentityIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          <form className={classes.form} onSubmit={SignIn} noValidate >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={updateField}
              name="username"
              label="Correo Electrónico"
              id="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={updateField}
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Iniciar Sesión
            </Button>

            <Grid container>
              <Grid item xs>
                  <Linkx variant="body2"
                  onClick={(event)=>(
                    setViewForget(true)
                  )}>
                    Olvidó su contraseña?
                  </Linkx>                  
                </Grid>
              {/*  <Grid item>
                    <Linkx  to="/registrarse" component={Link} variant="body2">
                      {"No tienes cuenta? Registrate"}
                    </Linkx>
                  </Grid> */}
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          </>
        :
        <>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Recuperar Contraseña
          </Typography>
          <form className={classes.form} onSubmit={SendPass} noValidate >
            <TextField
              value = {email.username}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={updateEmailField}
              name="emailrestablecer"
              label="Correo Electrónico"
              id="emailrestablecer"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Solicitar
            </Button>

            <Grid container>
              {<Grid item xs>
                <Linkx variant="body2"
                  onClick={(event)=>(
                    setViewForget(false)
                  )}>
                    Volver al inicio de sesión
                  </Linkx>                  
                </Grid>}
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </>
        }
          
        </div>
      </Grid>
    </Grid>
  );
}