import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import img500 from '../../../img/error500.svg';
import Box from '@material-ui/core/Box';


//styles
import useStyles from './styles'

export const Error500 = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <CssBaseline />

      <main className={classes.content}>

        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>

            <Card className={classes.card} variant="outlined">
              <CardContent className={classes.cardContent}>
                <Box display="flex"  justifyContent="center" flexDirection="column" p={1} >
                  <Box p={1} >
                    <Typography  variant="h4"  color="textSecondary" gutterBottom>
                    Error 500 : Internal Server Error
                    </Typography>
                  </Box>
                  <Box p={1} >
                    <img src={img500} className={classes.img} alt="error500" />
                  </Box>
                  <Box p={1} >
                    <Typography  variant="h5"  color="textPrimary" gutterBottom>
                      Se ha generado un error al momento de conectarse al servidor.
                    </Typography>
                  </Box>
                  <Box p={1} >
                    <Typography  variant="h6"  color="textSecondary" gutterBottom>
                      Por favor, contacte al proveedor de Hosting o al Administrador del sistema.
                    </Typography>
                  </Box>
                </Box>

              </CardContent>
         {/*      <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions> */}
            </Card>
              {/* <EnhancedTable
                title="Clientes"
                headCells={headCells}
                rows={clientes}
                idName={idName}
                Component={ClienteDialogForm}
              /> */}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div >
  );
}

export const Error404 = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <CssBaseline />

      <main className={classes.content}>

        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>

            <Card className={classes.card} variant="outlined">
              <CardContent className={classes.cardContent}>
                <Box display="flex"  justifyContent="center" flexDirection="column" p={1} >
                  <Box p={1} >
                    <Typography  variant="h4"  color="textSecondary" gutterBottom>
                    Error 404 : Página no encontrada
                    </Typography>
                  </Box>
                  <Box p={1} >
                    <img src={img500} className={classes.img} alt="error500" />
                  </Box>
                  <Box p={1} >
                    <Typography  variant="h5"  color="textPrimary" gutterBottom>
                      La página ingresada no existe.
                    </Typography>
                  </Box>
                  <Box p={1} >
                    
                  </Box>
                </Box>

              </CardContent>
         {/*      <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions> */}
            </Card>
              {/* <EnhancedTable
                title="Clientes"
                headCells={headCells}
                rows={clientes}
                idName={idName}
                Component={ClienteDialogForm}
              /> */}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div >
  );
}

export const UrlCaducado = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <CssBaseline />

      <main className={classes.content}>

        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>

            <Card className={classes.card} variant="outlined">
              <CardContent className={classes.cardContent}>
                <Box display="flex"  justifyContent="center" flexDirection="column" p={1} >
                  <Box p={1} >
                    <Typography  variant="h4"  color="textSecondary" gutterBottom>
                    Url Caducado
                    </Typography>
                  </Box>
                  <Box p={1} >
                    <img src={img500} className={classes.img} alt="error500" />
                  </Box>
                  <Box p={1} >
                    <Typography  variant="h5"  color="textPrimary" gutterBottom>
                      El link ingresado esta caducado. Por favor intente generando un nuevo link en la opcion de recuperar contraseña.
                    </Typography>
                  </Box>
                  <Box p={1} >
                    
                  </Box>
                </Box>

              </CardContent>
         {/*      <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions> */}
            </Card>
              {/* <EnhancedTable
                title="Clientes"
                headCells={headCells}
                rows={clientes}
                idName={idName}
                Component={ClienteDialogForm}
              /> */}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div >
  );
}