import React, { createContext, useContext, useState, useEffect } from 'react';
import { app } from "lib/firebase/firebaseConfig.js";
import { APIContext } from 'context';

export const PromocionesContext = createContext();

const PromocionesProvider = (props) => {

    const { promocionActual, mensajeAlert } = useContext(APIContext);
    const [ data, setData ] = useState({});

    useEffect(() => {
        setData(promocionActual)
     }, [promocionActual]);

    const update = async (data, id) => {
        try{
            await app.firestore()
                .collection('Promociones')
                .doc(id)
                .set(data)
                .then((snapshot) => {
                    console.log(snapshot.id)
                    console.log('Promoción Actualizada!');
                    mensajeAlert(data.nombre,'update')
                });
        } catch (e) {
            return e;
        }
      } 

    return (
        <PromocionesContext.Provider
            value={{
                data,
                update,
            }}
        >
            {props.children}
        </PromocionesContext.Provider>
    );
}

export default PromocionesProvider;