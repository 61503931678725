import React from "react"
import { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Button, Box} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import logo from '../img/logo.png';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';

import { APIContext } from '../context';

import {
  AdministradorListItems,
  ColaboradorListItems,
} from './MenuListItems';

const drawerWidthMini = 200;
const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  hiddenBar: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  appBar: {
    width: `calc(100% - ${drawerWidthMini}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    color: '#555252',
    backgroundColor: '#fbfbfb',
    boxShadow: '0 0 5px 0px'
  },
  appBarShift: {
    /* marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }), */
  },
  AppLogo: {
    backgroundColor: theme.palette.primary.dark,
    height: '65px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  AppLogoMenu: {
    height: '40px',
    maxWidth: '120px',
    paddingTop: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    
  },
  AppLogoMenuOpen: {
    width: 'auto',
  },

  appBarSpacer: theme.mixins.toolbar,

  toolbar: {
    justifyContent:'space-between',
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerWidthMini,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthMini,
    },
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  paper: {
    marginRight: theme.spacing(2),
    background: '#002b33',
    '& *': { color: 'rgba(255, 255, 255, 0.7)' },
  },
  title: {
    display: 'none',
    flexGrow: 1,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },

  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  button: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  text_button: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));


function AdministradorMenu() {
  return (
    <List>{AdministradorListItems}</List>
  );
}

function ColaboradorMenu() {
  return (
    <List>{ColaboradorListItems}</List>
  );
}

export default function AdminAppBar() {
  const classes = useStyles();
  let button;
  //let rol_usuario;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);

  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menu, setmenu] = useState(null);
  const { loggedUser,  logOut, promocionActual } = useContext(APIContext);

  useEffect(() => {
    if (loggedUser) {
      if (loggedUser.tipo === 'Administrador') {
        setmenu(<AdministradorMenu />);
      }
      if (loggedUser.tipo === 'Colaborador') {
        setmenu(<ColaboradorMenu />);
      }
    }
  }, [loggedUser]);

  async function handleLogout(e) {
    e.preventDefault();
    try {
      logOut();
      history.push("/");
    } catch (err) {
      alert('Error al momento de cerrar sesión, contacte con el administrador');
    }
  }

  const handleDrawerOpen = () => {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleMobileDrawerOpen = () => {
    if (open === true) {
      setOpenMobile(false);
    } else {
      setOpenMobile(true);
    }
  };

  const handleDrawerToggle = () => {
    setOpenMobile(!openMobile);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setUserMenuOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setUserMenuOpen((prevOpen) => !prevOpen);
  };

  /*   const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
    }; 
  
    const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
    };*/

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setUserMenuOpen(false);
    }
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Popper open={userMenuOpen} anchorEl={anchorEl} role={undefined} transition >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => { handleClose() }}>
              <MenuList autoFocusItem={userMenuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  return (
    <React.Fragment>
      <AppBar position="fixed" elevation={0}
        className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleMobileDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex">
          <CardGiftcardIcon />
            <Typography  className={classes.title}  color="primary">
           
            {promocionActual && promocionActual.nombre}
            </Typography>
          </Box>
          
          <div className={classes.sectionDesktop}>
            {button}
            {loggedUser && (

              <Button
                color="inherit"
                size="large"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                className={classes.button}
              >
                <AccountCircle style={{ fontSize: 30 }}/>
                <Typography variant="body2" className={classes.text_button}>
                {loggedUser.user}
                </Typography>
              </Button>

            )} </div>

        </Toolbar>
      </AppBar>
      {renderMenu}
      <Hidden smUp implementation="css">
        <Drawer
          //container={container}
          variant="temporary"
          //anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={openMobile}
          onClose={handleDrawerToggle}
          classes={{
            paper: clsx(classes.paper, classes.drawerPaper),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
           <div className={classes.AppLogo}>
          <img src={logo}
            className={clsx(classes.AppLogoMenu, open && classes.AppLogoMenuOpen)}
            alt="Wanabana Baby" />
            </div>
          {menu}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css" className={classes.hiddenBar}>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.paper, classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
          PaperProps={{
            onMouseEnter: handleDrawerOpen,
            onMouseLeave: handleDrawerOpen,
          }}
        >
          <div className={classes.AppLogo}>
          <img src={logo}
            className={clsx(classes.AppLogoMenu, open && classes.AppLogoMenuOpen)}
            alt="WanaBana Baby" />
          </div>
          
          {menu}
        </Drawer>
      </Hidden>

    </React.Fragment>
  );
}
