import React from 'react';
import { useState, useContext, useEffect } from 'react';
import {
	Grid,
	TextField,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
	IconButton
} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import EditIcon from '@material-ui/icons/Edit';

//Styles
import useStyles from './styles';

//Context
import { CiudadContext } from 'components/views/ciudad/context';
import { APIContext } from 'context'

const CiudadDialogForm = ({ dialogOpen, handleClose, row, edit }) => {

	const classes = useStyles();

	//APIContext
	const { ciudad, listCiudad } = useContext(APIContext);
	const { updateCiudad, createCiudad } = useContext(CiudadContext);

	// Validation Hook
	const [submit, setSubmit] = useState(false);
	const [active, setactive] = useState(null);

	const [enabledFields, setEnabledFields] = useState({
		ciu_nombre: true,
		ciu_estado: true,
	});

	const [data, setData] = useState({
        ciu_nombre:'',
		ciu_estado:true
    })

    const [inputError, setInputError] = useState({
        ciu_nombre:false
    });

    let array = inputError

    const [inputErrorTxt, setInputErrorTxt] = useState({
        ciu_nombre:''
    });

    let array_text = inputErrorTxt

	useEffect(() => {
		iniciar()
	}, []);

	function iniciar(){
		if (ciudad.length === 0) {
			listCiudad();
		}
		if(row){
			setData(row)
		}
	}

	
	const cleanEnabledFields = () => {
		setEnabledFields({
			ciu_nombre: true,
			ciu_estado: true,
		})
	}

	function handleChange(event){
		almacenarValor(event.target.name,event.target.value)
	}

	function handleBlur(index){
		if(validar_campos()){
			setSubmit(true)
		}else{
			setSubmit(false)
		}
	}

	const handleErrorInputChange = ( boolean, fieldType, mensaje) => {
        setInputError({...inputError,
            [fieldType]: boolean
        });
        setInputErrorTxt({...inputErrorTxt,
            [fieldType]: mensaje
        });
    };

    const almacenarValor = (field, value) => {
        setData({...data, [field] : value });
        handleErrorInputChange(false, field,'') 
    }

	function validar_campos(){
        let error = false

        if(data.ciu_nombre === ''){
            error = true; 
			array.ciu_nombre  = true;
            array_text.nom_cli  = 'Ingrese el Nombre de la ciudad';   
        }

        setInputError({...inputError,
            ...array
        });

        setInputErrorTxt({...inputErrorTxt,
            ...array_text
        });

        if (error === true){
            return false;
        }else{
            return true;
        }
    }

	function guardar(){
		if(!edit) {
			createCiudad(data)
		}else{
			updateCiudad(data)
		}
	}

	return (
		<>
			<Dialog
				open={dialogOpen}
				onClose={() => { handleClose(); cleanEnabledFields(); }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth={'sm'}
				fullWidth={true}
			>
				<DialogTitle id="alert-dialog-aprobar-causa-title">
					<Grid container spacing={3} justify="space-between">
						<Grid item xs={12} md={9} lg={9}>
							{edit ? `Ciudad ${row && row.ciu_nombre}` :  'Crear nuevo registro' }
          				</Grid>
					</Grid>
				</DialogTitle>

				<DialogContent dividers>

					<div className={classes.root}>
						<Grid container spacing={3} justify="space-between">
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="ciu_nombre"
									name="ciu_nombre"
									label="Nombre"
									defaultValue={row ? row.ciu_nombre : ''}
									value={data.ciu_nombre}
									onChange={(e) => {
										handleChange(e);
									}}
									onBlur={(e) => { handleBlur(e); cleanEnabledFields(); }}
									autoFocus
									error={inputError.ciu_nombre} 
									helperText={inputError.ciu_nombre ? inputErrorTxt.ciu_nombre : ''} 
									onMouseOver={() => { setactive("ciu_nombre") }}
									onMouseLeave={() => { setactive(null) }}
									InputProps={{
										readOnly: enabledFields.ciu_nombre && edit,
										endAdornment:
											<>
												{
													active === "ciu_nombre" && edit &&
													<InputAdornment position="start">
														<IconButton
															aria-label="edit"
															className={classes.margin}
															onClick={() => {
																setEnabledFields({ ...enabledFields, ciu_nombre: false });
																document.getElementById("ciu_nombre").focus();
															}}
														>
															<EditIcon fontSize="small" />
														</IconButton>
													</InputAdornment>
												}
											</>
									}}
								/>
							</Grid>

						</Grid>
					</div>

				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => { handleClose();  cleanEnabledFields(); }}
						color="secondary"
					>
						Cancelar
          			</Button>
					{edit ?
						<Button
							//type="submit"
							variant="contained"
							color="primary"
							disabled={!submit}
							onClick={(e) => { guardar(e); handleClose(); cleanEnabledFields(); }}
						>
							<CheckCircleRoundedIcon />
            				Guardar Cambios
          				</Button>
						:
						<Button
							//type="submit"
							variant="contained"
							color="primary"
							disabled={!submit}
							onClick={(e) => { guardar(e); handleClose();  }}
						>
							<CheckCircleRoundedIcon />
            				Guardar
          				</Button>
					}

				</DialogActions>

			</Dialog>

		</>
	)
}
export default CiudadDialogForm;