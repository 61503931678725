import React from "react"

import logo from 'img/wanabana-300.png';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Pulse from 'react-reveal/Pulse';
import Slide from 'react-reveal/Slide';

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    position: 'relative',
    color: theme.palette.common.white,
    //marginBottom: theme.spacing(4),
    background: theme.palette.primary.main,
    minHeight: '100vh',
    boxShadow: 'none',
    borderRadius: '0px',
    [theme.breakpoints.down('sm')]: {
      backgroundAttachment: 'scroll',
    },

  },
  heroContent: {
    background: 'inherit',
    boxShadow: 'none',
  },
  /* overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.58)',
  }, */
  Content: {
    position: 'relative',
    minHeight: '100vh',
    justifyContent: 'center',
    alignContent: 'center',
    //alignItems: 'last baseline',
  },
  ContentBrand: {
    //alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
    justifyContent: 'space-evenly',
    /* marginTop: theme.spacing(8), */
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: '80vh',
    },
  },
  ContentForm: {
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      height: '80vh',
    },
    /* marginTop: theme.spacing(8), */
  },
  slogan:{
    fontSize: '1.1rem',
    letterSpacing: '0.025em',
    fontWeight: '600',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  sloganBig:{
    fontSize: '1.8rem',
    fontWeight: '600',
    letterSpacing: '0.025em',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  HeroLogo: {
    //height: '15vmin',
    height: '300px',
    pointerEvents: 'none',
    maxWidth: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      height: '40vmin',
    },
  },
  SmartPhone: {
    pointerEvents: 'none',
    width: '25vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: '60vw',
    },
    [theme.breakpoints.down('xs')]: {
      height: '70vh',
      width: 'auto',
    },
  },
  
  brand:{
    //marginBottom: '8.5rem',
  },
 
}));

export default function MainFeaturedPost() {
  const classes = useStyles();

  return (
    <div component="main" className={classes.heroContainer}>
      <Container maxWidth="lg">
        <Paper  className={classes.heroContent} >
          <Grid container className={classes.Content}>
              <Grid item sm={12} md={5} className={classes.ContentBrand}>   
              <Slide bottom cascade> 
                <div className={classes.brand}>
                  <Pulse>
                    <img src={logo} className={classes.HeroLogo} alt="logo" />
                  </Pulse>
                </div>  
                
                  <Typography variant="h5" className={classes.sloganBig} color="inherit" gutterBottom>
                  
                  </Typography>
                </Slide>
                
              </Grid>
             
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}