import React from 'react';
import { useState, useContext, useEffect } from 'react';
import {
	Grid,
	TextField,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Input,
	IconButton
} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//Styles
import useStyles from './styles';

//Context
import { UsuariosContext } from '../../views/usuarios/context';
import { APIContext } from '../../../context'

const UsuarioDialogForm = ({ dialogOpen, handleClose, array, edit }) => {

	const classes = useStyles();

	const [showPassword, setShowPassword] = useState(false);
	// Vehiculo Context
	const {
		form,
		rol,
	} = useContext(UsuariosContext);

	//APIContext
	const { SignUp, usuarios,  listUsuarios, updateUsuario} = useContext(APIContext);

	if (!edit) {
		form["estado"] = 1;
	}

	const [fields, setFields] = useState({
		nombres: '',
		apellidos: '',
		identificacion: '',
		telefono:'',
		tipo:'',
		email:'',
		estado:1
	});

	const [error, setError] = useState({
		nombres: false,
		apellidos: false,
		identificacion: false,
		telefono:false,
		tipo:false,
		email:false,
	});
	const [ok, setOk] = useState(false);

	useEffect(() => {
		iniciar()
	}, []);

	function iniciar(){
		if (usuarios.length === 0) {
			listUsuarios();
		}
	}

	useEffect(() => {
		if (edit && array[0]) {
			setFields(array[0]);
		}
	}, [array, edit]);


	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	function validarCampos(){
		let arrayx = {
			nombres: false,
			apellidos: false,
			tipo:false,
			email:false,
			password:false,
			estado:false
		}

		let error = false

		if(fields.nombres === ''){
			arrayx.nombres = true
			error = true
		}
		if(fields.apellidos === ''){
			arrayx.apellidos = true
			error = true
		}
		if(fields.tipo === ''){
			arrayx.tipo = true
			error = true
		}
		if(fields.email === ''){
			arrayx.email = true
			error = true
		}
		if(fields.password === ''){
			arrayx.password = true
			error = true
		}
		setError(arrayx);
		setOk(!error)

	}

	function crearUsuario(){
		validarCampos()
		if(ok){
			SignUp(fields)
			handleClose()
		}
		
	}

	function actualizarUsuario(){
		validarCampos()
		if(ok){
			updateUsuario(fields)
			handleClose()
		}
	}

	return (
		<>
			<Dialog
				open={dialogOpen}
				onClose={() => { handleClose(); }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth={'md'}
				fullWidth={true}
			>
				<DialogTitle id="alert-dialog-aprobar-causa-title">
					<Grid container spacing={3} justify="space-between">
						<Grid item xs={12} md={9} lg={9}>
							{edit ? `Usuario ${fields.nombres} ${fields.apellidos}` :  'Crear nuevo registro' }
          				</Grid>
					</Grid>
				</DialogTitle>

				<DialogContent dividers>

					<div className={classes.root}>
						<Grid container spacing={3} justify="space-between">
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									id="nombres"
									name="nombres"
									label="Nombres"
									value={fields.nombres || ""}
									onChange={(e) => {
										setFields({...fields, "nombres":e.target.value});
									}}
									autoFocus
									error={(error.nombres) ? true : false}
									helperText={(error.nombres) ? 'Ingrese el Nombre del usuario': ''}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									id="apellidos"
									name="apellidos"
									label="Apellidos"
									value={fields.apellidos || ""}
									onChange={(e) => {
										setFields({...fields, "apellidos":e.target.value});
									}}
									error={(error.apellidos) ? true : false}
									helperText={(error.apellidos) ? 'Ingrese el Apellido del usuario': ''}
								/>
							</Grid>
							<Grid item xs={6} sm={3}>
								<TextField
									fullWidth
									id="identificacion"
									name="identificacion"
									label="Número de indentificación"
									value={fields.identificacion || ""}
									onChange={(e) => {
										setFields({...fields, "identificacion":e.target.value});
									}}
									error={(error.identificacion) ? true : false}
									helperText={(error.identificacion) ? 'Ingrese la identificación del usuario': ''}
								/>
							</Grid>

							<Grid item xs={6} sm={3}>
								<TextField
									fullWidth
									id="telefono"
									name="telefono"
									label="Teléfono"
									value={fields.telefono || ""}
									onChange={(e) => {
										setFields({...fields, "telefono":e.target.value});
									}}
									error={(error.identificacion) ? true : false}
									helperText={(error.identificacion) ? 'Ingrese el teléfono del usuario': ''}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6}>
								<FormControl className={classes.formControl} 
									/* error={("rol" in errors)} */
								>
									<InputLabel id="select-label">Tipo de Usuario</InputLabel>
									<Select
										autoWidth
										labelId="select-label"
										id="tipo"
										name="tipo"
										value={fields.tipo ? fields.tipo : ""}
										onChange={(e) => {
											setFields({...fields, "tipo":e.target.value});

										}}
										error={(error.identificacion) ? true : false}
										helperText={(error.identificacion) ? 'Seleccione el tipo de usuario': ''}
									>
										{
											rol.map((rol) => (
												<MenuItem key={rol.tipo + rol.value} value={rol.tipo}>{rol.tipo}</MenuItem>
											))
										}

									</Select>
{/* 									<FormHelperText>{("ase_rol" in errors) ? errors.ase_rol : 'Seleccione el rol del Usuario'}</FormHelperText>
 */}								</FormControl>
							</Grid>

							<Grid item xs={6} sm={6}>
								<TextField
									fullWidth
									id="email"
									name="email"
									label="Email"
									value={fields.email || ""}
									onChange={(e) => {
										setFields({...fields, "email":e.target.value});
									}}
									error={(error.email) ? true : false}
									helperText={(error.email) ? 'Ingrese el correo electrónico del usuario': ''}
								/>
							</Grid>
							
								<Grid item xs={6} sm={6}>
								{!edit &&
									<FormControl className={classes.formControl} 
										error={(error.password) ? true : false}
									>
										<InputLabel htmlFor="password">Contraseña</InputLabel>
										<Input
											id="password"
											name="password"
											type={showPassword ? 'text' : 'password'}
											value={fields.password || ""}
											onChange={(e) => {
												setFields({...fields, "password":e.target.value});
											}}
											
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														//onMouseDown={handleMouseDownPassword}
														size="medium"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
										<FormHelperText>{(error.password) ? 'Escriba la contraseña del Usuario' : ''}</FormHelperText>
									</FormControl>
								}
								</Grid>
						</Grid>
					</div>

				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => { handleClose();  }}
						color="secondary"
					>
						Cancelar
          			</Button>
					 
					{edit ?
					<>
						<Button
							//type="submit"
							variant="contained"
							color="primary"
							//disabled={!readyToSubmit}
							onClick={(e) => { actualizarUsuario(e);  }}
						>
							<CheckCircleRoundedIcon />
            				Guardar Cambios
          				</Button>
						  </>
						:
						<Button
							//type="submit"
							variant="contained"
							color="primary"
							//disabled={!readyToSubmit}
							onClick={(e) => { crearUsuario(e); }}
						>
							<CheckCircleRoundedIcon />
            				Guardar
          				</Button>
					}

				</DialogActions>

			</Dialog>

		</>
	)
}
export default UsuarioDialogForm;