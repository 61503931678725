import React ,{ useContext, useEffect } from 'react';
import logo from 'img/wanabana-300.png';
import {
  Box,
  Container,
  CssBaseline,
} from '@material-ui/core';
import 'moment/locale/es';
import Pulse from 'react-reveal/Pulse';

//styles
import useStyles from './styles'

//Context
//import { DashboardContext } from './context';
import { APIContext } from '../../../context';

const DashboardView = () => {

  const classes = useStyles();
  //const [ show, setShow ] =  useState(false);
  const { getPromocion } = useContext(APIContext);
  
  useEffect(() => {
    iniciar()
  }, []); 

  function iniciar(){
    getPromocion()
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <main className={classes.content}>

        <Container maxWidth="lg" className={classes.container}>
          <>
            {/* {show ? ( */}
            <div style={{height:'70vh',justifyContent: 'center'}} className={classes.paper}>
                 <Box display={'flex'} justifyContent={'center'}>
                 <Pulse>
                    <img src={logo} className={classes.HeroLogo} alt="logo" />
                  </Pulse>
                 </Box>
            </div>
            {/* ) : (
              <div className="loading_container">
                <img src={loading} className="loading" alt="cargando..." />
              </div>
            )} */}
          </>
        </Container>
      </main>
    </div>
  );
}

export default DashboardView;