import React, { createContext, useState, useContext  } from 'react';
import { app } from "lib/firebase/firebaseConfig.js";
import { APIContext } from 'context';
import CustomToolbar from '../../../customToolbar';
import CiudadDialogForm from 'components/forms/ciudadForm';
import AddIcon from "@material-ui/icons/Add";
import {
  Switch,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export const CiudadContext = createContext();

const CiudadProvider = (props) => {

    const [open, setOpen] = useState(false);
    const [row, setRow] = useState({})
    const [form, setForm] = useState({
        ciu_nombre: "",
        ciu_estado: true,
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const { 
      ciudad, 
      mensajeAlert,
      unCheck,
    } = useContext(APIContext);


    function handleChange(index){
      let x = ciudad[index]
      x.ciu_estado = !ciudad[index].ciu_estado
      updateCiudad(x)
    }

    const createCiudad = async (data) => {
      try{
          await app.firestore()
            .collection('Ciudad')
            .add(data)
            .then((snapshot) => {
                console.log('Nueva Ciudad Agregada!');
                mensajeAlert(data.ciu_nombre,'create')
            });
      } catch (e) {
          return e;
      }
    } 

    const updateCiudad = async (data) => {
      try{
          await app.firestore()
              .collection('Ciudad')
              .doc(data.id)
              .set(data)
              .then((snapshot) => {
                  console.log('Ciudad Actualizada!');
                  mensajeAlert(data.ciu_nombre,'update')
              });
      } catch (e) {
          return e;
      }
    } 

    const deleteCiudad = async (data) => {
      try{
          if(await validar){
            await app.firestore()
            .collection('Ciudad')
            .doc(data.id)
            .delete()
            .then((snapshot) => {
                console.log('Ciudad Eliminada');
                mensajeAlert(`${data.ciu_nombre}`,'delete')
            });
          }else{
            mensajeAlert('La ciudad ya se encuentra utilizada en un registro, por lo cual no puede ser eliminada','Error_raw')
          }
      } catch (e) {
          return e;
      }
    } 

    async function validar(){
      return new Promise(async function(resolve, reject) {
        resolve(false)
      })
    }

    const columns = [
        { name: 'ciu_nombre', label: 'Nombre' },
        {
          name: "ciu_estado",
          label: 'Estado',
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
                return (
                  <Switch
                    checked={ciudad[dataIndex].ciu_estado}
                    onChange={(event) => (handleChange(dataIndex))}
                    name="estado"
                    color="primary"
                  />
                );
            }
          }
        },
        {
          name: "id",
          label:" ",
          options: {
    
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {              
              return (
                <>
                <Tooltip title="Editar/Ver">
                  <IconButton
                    onClick={(e)=>{
                      e.preventDefault()
                      setRow(ciudad[tableMeta.rowIndex],
                        handleDialogOpen()
                      )
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                </>
              );
            }
          }
        },
    
    ];
     
    const options = {
        responsive: 'standard',
        filter: true,
        filterType: "multiselect",
        textLabels: {
          body: {
            noMatch: "Lo siento, no se encuentran Registros",
            toolTip: "Sort",
            columnHeaderTooltip: column => `Sort for ${column.label}`
          },
          pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Registros por página:",
            displayRows: "de",
          },
          toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "ver Columnas",
            filterTable: "Filtrar Tabla",
          },
          filter: {
            all: "TODOS",
            title: "FILTROS",
            reset: "RESTABLECER",
          },
          viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Mostrar/Ocultar Columnas",
          },
          selectedRows: {
            text: "Registro(s) seleccionados",
            delete: "Eliminar",
            deleteAria: "Eliminar Registro(s) seleccionados",
          },
        },
        onRowsDelete:(rowsDeleted) => {
            const array = rowsDeleted.data
            array.forEach((arr,index)=>{
              deleteCiudad(ciudad[arr.dataIndex])
            })
            unCheck(); 
            return false
        },
        customToolbar: () => {
          return (
            <CustomToolbar Component={CiudadDialogForm} CustomIcon={AddIcon} />
          );
        },
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
      };
      const handleDialogClose = () => {
        setDialogOpen(false)
      }

    const clearForm = () => {
        setForm({
            ciu_nombre: "",
            ciu_estado: true,
        })
    }

    return (
        <CiudadContext.Provider
            value={{
                form,
                open,
                createCiudad,
                updateCiudad,
                deleteCiudad,
               
                columns,
                options,
                dialogOpen,
                row,

                clearForm,
                setOpen,
                handleDialogOpen,
                handleDialogClose,
            }}
        >
            {props.children}
        </CiudadContext.Provider>
    );
}

export default CiudadProvider;