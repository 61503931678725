import React, { useContext, useState, useEffect } from 'react';
import {
    makeStyles,
    Card,
    CardContent,
    Typography,
    Box,
    Switch,
    TextField,

} from '@material-ui/core';
import { PromocionesContext } from '../../promociones/context'

const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
    formControl: {
        //padding: theme.spacing(1, 2, 2 , 2),
    },
    TituloForm: {
        textTransform: 'Uppercase',
        fontWeight: '600',
        marginBottom: theme.spacing(2),
    },
    SubTituloForm:{
        marginBottom: theme.spacing(2),
    },
    Divider: {
        margin: theme.spacing(3, 0, 3, 0),
        backgroundColor: theme.palette.primary.main,
        height: '2px',
    },
    Input: {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,

    },
    InputBase: {
        textTransform: 'Uppercase',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main,
    },
    card: {
       // boxShadow: 'none',
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        padding: theme.spacing(2, 2, 2, 2),
        borderColor: '#cccecea3',
        marginBottom: theme.spacing(3),
    },
    containerButton: {
        textAlign: 'center'
    },
    buttonCalcular: {
        textTransform: 'capitalize',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.1rem',
        fontSize: '1.2rem',
        color: '#fff',
        float: 'right'
    },
    label:{
        display:'flex',
        alignItems:'center',
        width:'280px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
          },
    },
    input:{
        [theme.breakpoints.up('sm')]: {
            width: 400
        },
    },
    boxContainer:{
        [theme.breakpoints.down('sm')]: {
            flexDirection:"column",
        },
        flexDirection:"row",
    },

}));

export default function InfoCard({data, id}) {

    const classes = useStyles();
    const { update } = useContext(PromocionesContext);
    const [ datax, setDatax ] = useState({});
    const [ inputError, setInputError] = useState({
        nombre:false
    });

    let array = inputError

    const [inputErrorTxt, setInputErrorTxt] = useState({
        nombre:''
    });

    let array_text = inputErrorTxt

   /*  useEffect(() => {
        if (loggedUser) {
          if ((loggedUser.ase_rol < 4)) {
            setPermiso(true);
          }
        } 
    }, [loggedUser]); */

    useEffect(() => {
        iniciar()
    }, []);

    function iniciar(){
        setDatax(data)
    }

    function handleChange(event){
		almacenarValor(event.target.name,event.target.value)
	}

    const almacenarValor = (field, value) => {
        setDatax({...datax, [field] : value });
        handleErrorInputChange(false, field,'') 
    }

	function validar_campos(){
        let error = false

        if(datax.nombre === ''){
            error = true; 
			array.nombre  = true;
            array_text.nombre  = 'Ingrese el Nombre de la promoción';   
        }

        setInputError({...inputError,
            ...array
        });

        setInputErrorTxt({...inputErrorTxt,
            ...array_text
        });

        if (error === true){
            return false;
        }else{
            return true;
        }
    }

    const handleErrorInputChange = ( boolean, fieldType, mensaje) => {
        setInputError({...inputError,
            [fieldType]: boolean
        });
        setInputErrorTxt({...inputErrorTxt,
            [fieldType]: mensaje
        });
    };

	function guardar(){
        if(validar_campos()){
            if(datax.nombre !== data.nombre){
                update(datax, id)
            }
        }
	}

    function guardarEstado(value){
        if(validar_campos()){
            setDatax({...datax, ['activo'] : value });
            let arr = datax
            arr.activo = value
            update(arr, arr.id)
        }
	}

    return (
        <>
        <Card className={classes.card} variant="outlined" >
            <CardContent className={classes.FormBase}> 
                <Box  display="flex" flexDirection="column" p={0} mt={3}>
                    <Box  mb={3}>
                        <Typography color='primary' variant='h4'>
                            Promoción
                        </Typography>
                        <Typography style={{color:'gray'}} variant='body2'>
                            Id: {datax.id}
                        </Typography>
                    </Box>
                    <Box  display="flex" className={classes.boxContainer} p={0} my={1}>
                        <Box className={classes.label} mr={4}>
                            <Typography color='primary' align='left'>
                                Nombre
                            </Typography>
                        </Box>
                        <Box className={classes.input}>
                            <TextField
                                name="nombre"
                                value={datax.nombre || ''}
                                variant="outlined"
                                placeholder=" Nombre de la Promoción"
                                fullWidth 
                                size="small"
                                onChange={(event)=>(
                                    handleChange(event)
                                )}
                                onBlur={()=>(
                                    guardar()
                                )}
                            />
                        </Box>
                    </Box>
                   
                    <Box  display="flex" flexDirection="row" p={0} my={1}>
                        <Box className={classes.label} mr={4}>
                            <Typography color='primary' align='left'>
                                Estado
                            </Typography>
                        </Box>
                        <Box mr={2}>
                            
                            <Switch
                                value = {datax.activo || true}   
                                checked={datax.activo ? true : false}
                                onChange={(event) => {
                                    //almacenarValor('activo',!datax.activo)
                                    guardarEstado(!datax.activo)
                                }}
                                name="estado"
                                color="primary"
                            />

                        </Box>
                    </Box>
                   
                </Box>
               
                
            </CardContent>
        </Card>
        </>
    )
}


