import React, { useContext } from 'react';
import {
  CssBaseline,
  Container,
  Grid
}
from '@material-ui/core';
import InfoCard from './parts/infoCard'
import { ImageLoading } from 'components/loading';

//styles
import useStyles from './styles'

//Context
import { PromocionesContext } from '../promociones/context';

const PromocionView = () => {

  const classes = useStyles();
  const { data } = useContext(PromocionesContext);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <main className={classes.content}>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>
              {data ? (
                <InfoCard data={data} id={data.id} />
              ) : (
                  <ImageLoading />
              )}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
  //}
}
export default PromocionView;