import React, { createContext, useState, useContext  } from 'react';
import { APIContext } from '../../../../context/';
import {  Button } from '@material-ui/core';

export const DashboardContext = createContext();

const DashboardProvider = (props) => {

    const ruta_existe = `cotizacion/search?ase_id`;

    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({
        ase_nombre: "",
        password: "",
        ase_tipide: "",
        ase_numide: "",
        ase_email: "",
        ase_telefono: "",
        ase_telefono2: "",
        ase_rol: "",
        ase_estado: "",
    });

    const [editableObject, seteditableObject] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const { usuarios,
        unCheck,verificar_borrar,deleteUsuario,
    } = useContext(APIContext);

    const headCells = [
        // Toma, tipo, pasajeros, cilindrada, combustible
        { id: 'ase_id', numeric: true, disablePadding: false, label: '#' },
        { id: 'ase_nombre', numeric: false, disablePadding: false, label: 'Nombre' },
        { id: 'ase_tipide_nom', numeric: true, disablePadding: false, label: 'Tipo de identificación' },
        { id: 'ase_numide', numeric: false, disablePadding: false, label: 'Número de identificación' },
        { id: 'ase_email', numeric: false, disablePadding: false, label: 'Email' },
        { id: 'ase_telefono', numeric: false, disablePadding: false, label: 'Teléfono' },
        { id: 'ase_telefono2', numeric: false, disablePadding: false, label: 'Teléfono2' },
        { id: 'ase_rol_nom', numeric: true, disablePadding: false, label: 'Rol' },
        { id: 'ase_estado_nom', numeric: true, disablePadding: false, label: 'Estado' },

    ];

    const idName = "ase_id";

    const data = ''

    const tipo_ide = [
        { value: 1, tipo: "Cédula" },
        { value: 2, tipo: "RUC" },
        { value: 3, tipo: "Pasaporte" }
    ]

    const estado = [
        { value: 1, tipo: "Activo" },
        { value: 2, tipo: "Inactivo" },
    ]

    const rol = [
        { value: 1, tipo: "Desarrollador" },
        { value: 2, tipo: "Administrador" },
        { value: 3, tipo: "Colaborador" },
    ]

    const columns = [
        //{ name: 'id', label: '#' },
        { name: 'ase_nombre', label: 'Nombre' },
        { name: 'ase_tipide_nom', label: 'Tipo de identificación' },
        { name: 'ase_numide', label: 'Número de identificación' },
        { name: 'ase_email', label: 'Email' },
        { name: 'ase_telefono', label: 'Teléfono' },
        { name: 'ase_telefono2', label: 'Teléfono2' },
        { name: 'ase_rol_nom', label: 'Rol' },
        { name: 'ase_estado_nom', label: 'Estado' },
        {
          name: "",
          options: {
    
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              const data = usuarios[dataIndex];
    
              return (
                <>
    
                  <Button variant="outlined" color="primary"
                    onClick={(e) => {
                      seteditableObject(data);
                      handleDialogOpen();
                    }}>
                    Ver
                  </Button>
    
                </>
              );
            }
          }
        }
    
    ];
     
    const options = {
        filter: true,
        filterType: "multiselect",
        textLabels: {
          body: {
            noMatch: "Lo siento, no se encuentran Registros",
            toolTip: "Sort",
            columnHeaderTooltip: column => `Ordenar por ${column.label}`
          },
          pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Registros por página:",
            displayRows: "de",
          },
          toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "ver Columnas",
            filterTable: "Filtrar Tabla",
          },
          filter: {
            all: "TODOS",
            title: "FILTROS",
            reset: "RESTABLECER",
          },
          viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Mostrar/Ocultar Columnas",
          },
          selectedRows: {
            text: "Registro(s) seleccionados",
            delete: "Eliminar",
            deleteAria: "Eliminar Registro(s) seleccionados",
          },
        },
        onRowsDelete:(rowsDeleted) => {
            const array = rowsDeleted.data
            const DeleteId = array.map((arr,index)=>{
                return usuarios[arr.dataIndex].id
            })

            array.map((arr,index)=>{
                let id     = usuarios[arr.dataIndex].id
                let nombre = usuarios[arr.dataIndex].ase_nombre
                verificar_borrar(ruta_existe, id, nombre, DeleteId)
                .then(function(result) {
                    if(result){
                        deleteUsuario(id, nombre, DeleteId); 
                    }
                });
                return arr
            })
            unCheck();
            return false
        },
        
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
      };
      const handleDialogClose = () => {
        setDialogOpen(false)
      }

    const clearForm = () => {
        setForm({
            ase_nombre: "",
            password: "",
            ase_tipide: "",
            ase_numide: "",
            ase_email: "",
            ase_telefono: "",
            ase_telefono2: "",
            ase_rol: "",
            ase_estado: "",
        })
    }

    return (
        <DashboardContext.Provider
            value={{
                form,
                open,
                headCells,
                idName,
                columns,
                options,
                data,
                dialogOpen,
                editableObject,
                tipo_ide,
                rol,
                estado,

                clearForm,
                setOpen,
                handleDialogOpen,
                handleDialogClose,
            }}
        >
            {props.children}
        </DashboardContext.Provider>
    );
}

export default DashboardProvider;