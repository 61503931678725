import React, { createContext, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { app } from "lib/firebase/firebaseConfig.js";

export const APIContext = createContext();

const APIProvider = (props) => {
    
    useEffect(() => {
        app.auth().onAuthStateChanged(function(user) {
            //setUsuario(user);
            //setShowChild(true);
        });
    }, []);

    //const [usuario, setUsuario] = useState(null);
    //const [galeria, setGaleria] = useState([]);
    //const [showChild, setShowChild] = useState(false);

    const [loggedUser, setLoggedUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading_img, setLoading_img] = useState(false);
    const [edition, setEdition] = useState(true);

    const [dialogSuccessOpen, setDialogSuccessOpen] = useState(false);
    const [dialogAlertOpen, setDialogAlertOpen] = useState(false);
    const [dialogAlertContent, setDialogAlertContent] = useState({
        titulo: "",
        mensaje: "",
        imagen:"",
    });
    const { enqueueSnackbar } = useSnackbar();

    const [usuarios, setUsuarios] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [authenticated, setauthenticated] = useState(null);
    const [promocionActual, setPromocionActual] = useState(null)
    const [ciudad, setCiudad] = useState([]);

    function mensajeAlert(nombre,tipo){
        let mensaje = ''
        let variant = 'default'

        if(tipo === 'create'){
            mensaje = `El registro ${nombre} se agregó con exito.`;
            variant = 'success'
        }
        if(tipo === 'create_raw'){
            mensaje = `${nombre}`;
            variant = 'success'
        }
        if(tipo === 'update'){
            mensaje = `El registro ${nombre} se actualizo con exito.`;
            variant = 'info'
        }
        if(tipo === 'delete'){
            mensaje = `Se elimino el registro ${nombre} con exito.`;
            variant = 'success'
        }
        if(tipo === 'warning'){
            mensaje = `No se puede eliminar el registro ${nombre} por que se encuentra utilizado en otro registro.`;
            variant = 'warning'
        }
        if(tipo === 'error'){
            mensaje = `Hubo un error con el registro ${nombre}, no se logro eliminar.`;
            variant = 'error'
        }
        if(tipo === 'error_raw'){
            mensaje = `${nombre}`;
            variant = 'error'
        }
        enqueueSnackbar(mensaje, { 
            variant: variant,
        });
        
    }

    function DialogAlert(titulo, mensaje, imagen){
        setDialogAlertOpen(true);
        setDialogAlertContent({
            ...dialogAlertContent,
            'titulo' : titulo,
            'mensaje' : mensaje,
            'imagen': imagen
        });
        
    }

    function verificar_correo(email){
        if(email !== ''){
            if(typeof email !== "undefined"){
                let lastAtPos = email.lastIndexOf('@');
                let lastDotPos = email.lastIndexOf('.');
        
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && 
                    lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                        return [false, 'El Email ingresado es invalido']
                }else{
                    return [true , '']
                }
            } 
        }else{
            return [false, 'Ingrese su Email']
        } 
    }

    function verificar_identificacion(tipo, identificacion){
        let numeroCaracteres = identificacion.length;
        if(identificacion !== ''){
            if(tipo === 1){
                if(numeroCaracteres !== 10 ){
                    return [false, 'Número de cédula invalido. Ingrese los 10 digitos de su identificación']
                }else{
                    return [true , '']
                }
            }else{
                if(tipo === 2){
                    if(numeroCaracteres !== 13){
                        return [false, 'Número de RUC invalido. Ingrese los 13 digitos de su identificación']
                    }else{
                        return [true , '']
                    }
                }else{
                    if(numeroCaracteres > 3){
                        return [true , '']
                    }else{
                        
                        return [false , 'Número de Pasaporte invalido. Ingrese más de 3 digitos de su identificación']
                    }  
                }
            }
        }else{
            return [false, 'Número de Identifación vacio']
        }
    }

    function verificar_celular(numero){
        let numeroCaracteres = numero.length;
        if(numero !== ''){
            if(typeof numero !== "undefined"){
                if(numeroCaracteres !== 10 ){
                    return [false, 'Número de celular invalido. Ingrese 10 digitos de su numero celular']
                }else{
                    return [true , '']
                }
            } 
        }else{
            return [false, 'Número de celular vacio']
        } 
    }

    function unCheck() {
        const x = document.querySelectorAll("th.MuiTableCell-head input")[0];
        x.click()
    } 

    const getImage = async (files) => {
        return new Promise((resolve, reject) => {
            try {
                app.storage()
                .ref(files)
                .getDownloadURL()
                .then( async function(url)  {
                    resolve(url);   
                });
            } catch (error) {
                mensajeAlert(error,'error_raw')
                console.log(error);
                reject('')
            }
        });
    }

    // USUARIOS
    const listUsuarios = async () => {
        try {
            app.firestore()
            .collection('Users')
            .onSnapshot((querySnapshot)=>{
                const docs = [];
                querySnapshot.forEach(doc =>{
                    let nomestado = ''
                    if(doc.data().estado === 1){
                        nomestado = 'Activo'
                    }else{
                        nomestado = 'Inactivo'
                    }
                    docs.push({...doc.data(), id:doc.id, nom_estado: nomestado })
                })
                setUsuarios(docs);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const getUsuario = async (id) => {
        return new Promise(async function(resolve, reject) {
                let acceso = false
                app.firestore()
                .collection('Users')
                .doc(id)
                .get()
                .then(function(doc) {
                    if (doc.exists) {
                        if((doc.data().tipo === 'Administrador')
                            ||
                            (doc.data().tipo === 'Colaborador')
                        ){
                            if(doc.data().estado === 1){
                                acceso = true
                            }else{
                                acceso = false
                            }
                        }else{
                            acceso = false
                        }
                    } else {
                        acceso = false
                    }
                    resolve(acceso)
                })
                .catch(function(error) {
                    console.log("Error Update document:", error);
                    reject(false)
                })
            }).then(function(result) {
                return result;
            })
        //})
    }

    const getUsuarioMeta = async (id) => {
        return new Promise(async function(resolve, reject) {
            await app.firestore()
                .collection('Users')
                .doc(id)
                .get()
               .then(function(doc) {
                if (doc.exists) {
                    resolve(doc.data())
                } else {
                    reject ({})
                }
            })
        }) 
    }

    const updateUsuario = async (data) => {
        try{
            await app.firestore()
                .collection('Users')
                .doc(data.id)
                .set(data)
                .then((snapshot) => {
                    console.log('MetaData de Usuario actualizado!');
                    mensajeAlert(data.nombres,'update')
                });
        } catch (e) {
            return e;
        }
    }

    const deleteUsuario = async (id) => {
        try{
            if(!id){
                throw new Error('')
            }else{
                await app.firestore()
                .collection('Users')
                .doc(id)
                .delete()
                .then((snapshot) => {
                    console.log('Usuario eliminado');
                    mensajeAlert('','delete')
                });
            }
            
        } catch (e) {
            mensajeAlert('','error')
            return e;
        }
    }

    async function SignUp(data){
        let resp = false
        let datax = ''
        await app.auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then((userCredentials)=>{
            console.log("usuario creado en Auth")
            if(userCredentials.user){
                data.uid = userCredentials.user.uid
                datax = data
                userCredentials.user.updateProfile({
                    displayName: data.nombres,
                    photoURLL: ''
                }).then(()=>{
                    createUsuario(data)
                })
            }
            resp = true
        })
        .catch(error => {
            if (error.code === 'auth/email-already-in-use') {
            mensajeAlert('El correo electrónico ingresado ya se encuentra utilizada.','error_raw')
            }else{
                if (error.code === 'auth/invalid-email') {
                    mensajeAlert('El correo electrónico ingresado es incorrecto!','error_raw')
                }else{
                    if (error.code === 'auth/weak-password') {
                        mensajeAlert('Contraseña ingresada muy debil. Intente agregando máximo 6 carácteres','error_raw')
                    }else{
                        mensajeAlert(error,'error_raw')
                    }
                }
            }
        });
        return [resp, datax]
    }

    const createUsuario = async (data) => {
        try{
        await app.firestore()
        .collection('Users')
        .doc(data.uid)
        .set(data)
        .then((snapshot) => {
            console.log('MetaData de Usuario guardado!');
            setUsuarios([...usuarios, data]);
            mensajeAlert(data.nombres,'create')
        });
        } catch (e) {
            return e;
        }
    }

    // AUTH LOGIN
    const signIn = async (data) => {
        setLoading(true)
        try {
            const App = await app.auth()
            const response = await App.signInWithEmailAndPassword(data.username, data.password)
            const token = response;
            if (response) {
                let x = await getUsuario(response.user.uid)
                if(x){
                    const y = await getUsuarioMeta(response.user.uid)
                    await getUser(y.email, y.tipo);
                    setToken(token);
                    setauthenticated(true);
                    localStorage.setItem('token', token);
              
                    setLoading(false)
                    return true
                }else{
                    throw new Error('El usuario no puede acceder porque no dispone de los permisos necesarios por el administrador');
                }
                
            }
        } catch (err) {
            setLoading(false)
            if (err instanceof TypeError) {
                DialogAlert('Error al ingresar',"Se ha generado un error interno, comuníquese con el administrador del sistema")
                return false;
            } else {
                DialogAlert('Error al ingresar',err.message)
                return false;
            }
        }
    }

    // USER
    const getUser = async (username, tipo) => {
        try {
            localStorage.setItem('user', username);
            localStorage.setItem('tipo', tipo);
            setLoggedUser({...loggedUser,
                'user':username,
                'tipo':tipo,
            })
        } catch (error) {
            console.log(error);
        }
    }

    // RECUPERAR CONTRASENA
    const sendPasswordEmail = async (data) => {
        setLoading(true)
        const App = await app.auth()
        await App.sendPasswordResetEmail(data).then(function() {
            setLoading(false)
            DialogAlert('Restablecer contraseña','Se ha enviado el link para restablecer su contraseña a su correo electrónico.')
            return 
        }).catch(function(err) {
            setLoading(false)
            DialogAlert('Restablecer contraseña',err.message)
            return err.message;
        });

    }

    const logOut = async() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('tipo');
        setLoggedUser(null);
        setToken(null);
        setauthenticated(false);
        try{
            const App = await app.auth()
            await App.signOut()
        }catch(error) {
            DialogAlert('Cerrar sesión',error.message)
            return error
        }
       
    }

    const checkAuth = () => {
        const tokenStoraged = localStorage.getItem('token');
        const userStoraged = localStorage.getItem('user');
        if (!tokenStoraged || !userStoraged) {
        //if (!tokenStoraged) {
            return false;
        } else {
          
            return true;
        }
    }

    const checkUser = async() => {
        const tokenStoraged = localStorage.getItem('token');
        const userStoraged  = localStorage.getItem('user');
        const tipoStoraged  = localStorage.getItem('tipo');

        if ((!userStoraged) || (!tipoStoraged) ||  (tokenStoraged === null)) {
            logOut()
        } else {
            await getUser(userStoraged, tipoStoraged);
            setToken(tokenStoraged);
            setauthenticated(true);
        }
    }

    const getPromocion = async () => {
        //return new Promise((resolve, reject) => {
            try {
                app.firestore()
                .collection('Promociones')
                .onSnapshot((querySnapshot)=>{
                    let x = {};
                    querySnapshot.forEach(doc =>{
                        if(doc.data().activo){
                            x = doc.data()
                            x.fecha_inicio = new Date(doc.data().fecha_inicio.seconds*1000);
                            x.id = doc.id   
                        }
                    })
                    setPromocionActual(x)
                    //resolve(x)
                });
            } catch (error) {
                console.log(error);
                //reject({})
            }
        //});
    }

    const listCiudad = async () => {
        try {
            app.firestore()
            .collection('Ciudad')
            .onSnapshot((querySnapshot)=>{
                const docs = [];
                
                querySnapshot.forEach(doc =>{
                    docs.push({...doc.data(), id:doc.id })
                })
                setCiudad(docs);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const getCiudad = async (id) => {
        return new Promise(async function(resolve, reject) {
            app.firestore()
            .collection('Ciudad')
            .doc(id)
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    resolve(doc.data())
                }
            })
            .catch(function(error) {
                console.log("Error al buscar Ciudad:", error);
                reject({})
            })
        }).then(function(result) {
            return result;
        })
    }

    return (
        <APIContext.Provider
            value={{
                edition,
                usuarios,     
                loggedUser,
                token,
                authenticated,
                loading,
                loading_img,
                dialogSuccessOpen,
                dialogAlertOpen,
                dialogAlertContent, 

                unCheck,
                mensajeAlert,
                verificar_correo,
                verificar_identificacion,
                verificar_celular,
                setDialogSuccessOpen,
                setDialogAlertOpen,
                setDialogAlertContent,
                setEdition,
                setLoading,
                setLoading_img,
                checkUser,
                setLoggedUser,
               
                listUsuarios,
                SignUp,
                createUsuario,
                signIn,
                sendPasswordEmail,
                
                logOut,
                updateUsuario,             
                deleteUsuario,
                checkAuth,

                getImage,
                getUsuario,
                getPromocion,
                promocionActual,
                listCiudad,
                getCiudad,
                ciudad, setCiudad
                
            }}
        >
            {props.children}
        </APIContext.Provider>
    );
}

export default APIProvider;