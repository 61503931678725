import React, { createContext, useState, useContext  } from 'react';
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CsvDownload from 'react-json-to-csv'

import useStyles from '../styles'
import { APIContext } from 'context';
import { app } from "lib/firebase/firebaseConfig.js";

export const ParticipantesContext = createContext();

const ParticipantesProvider = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [eliminarOpen, setEliminarOpen]=  useState(false);
    const [eliminarId, setEliminarId]=  useState('');

    const [data, setData] = useState([]);
    const [dataExport, setDataExport] = useState([]);
    const { 
      loggedUser,
      promocionActual
    } = useContext(APIContext);

    const columns = [
        //{ name: 'id', label: '#' },
        { name: 'nom_cli', label: 'Nombres' },
        { name: 'email', label: 'Email' },
        { name: 'ced_cli', label: 'CI/RUC' },
        {
          name: "id",
          label:" ",
          options: {
    
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
              const x = data[tableMeta.rowIndex];

              return (
                <>
                <Tooltip title="Ver Foto de Factura">
                  <IconButton
                    onClick={(e)=>{
                      e.preventDefault()
                      buscarImagen(promocionActual.id,`${x.ced_cli}-${x.fac_num}-${x.id}`)
                    }}
                  >
                    <ImageIcon />
                  </IconButton>
                </Tooltip>
             
                <Tooltip title="Mostrar más Información">
                  <IconButton
                    onClick={(e)=>{
                      e.preventDefault()
                      history.push(`participantes/${data[tableMeta.rowIndex].id}`)
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Tooltip>

                {/* <Tooltip title="Eliminar">
                  <IconButton
                    onClick={(e)=>{
                      e.preventDefault()
                      console.log(data.uid)
                      setEliminarId(value ? value : data.uid)
                      setEliminarOpen(true)
                    }}
                  >
                    <DeleteOutlineIcon 
                      style={{ color: '#f47373' }} 
                    />
                  </IconButton>
                </Tooltip> */}
                </>
              );
            }
          }
        },
    
    ];
     
    const options = {
      responsive: 'standard',
      selectableRows: 'none',
      download: false,
      filter: true,
      filterType: "multiselect",
      textLabels: {
          body: {
            noMatch: "Lo siento, no se encuentran Registros",
            toolTip: "Sort",
            columnHeaderTooltip: column => `Ordenar por ${column.label}`
          },
          pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Registros por página:",
            displayRows: "de",
          },
          toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "ver Columnas",
            filterTable: "Filtrar Tabla",
          },
          filter: {
            all: "TODOS",
            title: "FILTROS",
            reset: "RESTABLECER",
          },
          viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Mostrar/Ocultar Columnas",
          },
          selectedRows: {
            text: "Registro(s) seleccionados",
            delete: "Eliminar",
            deleteAria: "Eliminar Registro(s) seleccionados",
          },
        },
        customToolbar: () => {
          return (
            <>
            {loggedUser && loggedUser.tipo === 'Administrador' &&
           <>
             <CsvDownload 
              data={dataExport}
              filename="participantes.csv"
              className={classes.buttonExport}
            >
              Exportar
            </CsvDownload>
          </>
          } 
            </>
          );
        }, 
        
    }

    async function buscarImagen(id,path){
      window.open( await getImage(id,path));           
    }

    const listParticipantes = async (id) => {
      try {
          app.firestore()
          .collection('Promociones')
          .doc(id)
          .collection('Data')
          .onSnapshot((querySnapshot)=>{
              const docs = [];
              querySnapshot.forEach(doc =>{
                  docs.push({...doc.data(), id:doc.id })
              })
              listParticipantesExport(docs)
              setData(docs);
          });
      } catch (error) {
          console.log(error);
      }
    }

    function listParticipantesExport(datax){
      let array = []
      let obj = {}
      datax.forEach((value)=>{
        obj = {
          nombre_cliente:value.nom_cli,
          email:value.email,
          ci_ruc:value.ced_cli,
          fecha_registro: new Date(value.fecha.seconds*1000).toLocaleDateString()
        }
        array.push(obj)
      })
      setDataExport(array)
    }

    const getParticipante = async (promoid,particId) => {
      return new Promise((resolve, reject) => {
        try {
            app.firestore()
            .collection('Promociones')
            .doc(promoid)
            .collection('Data')
            .doc(particId)
            .get()
            .then((doc) => {
              if (doc.exists) {
                  resolve(doc.data())
              } else {
                  console.log("No existe Data del participante!");
                  resolve({})
              }
            }).catch((error) => {
                console.log("Error opteniendo Data del participante", error);
            });
        } catch (error) {
            console.log(error);
            reject({})
        }
      });
    }

    const getImage = async (id, file) => {
      return new Promise((resolve, reject) => {
          try {
              app.storage()
              .ref(`promociones/${id}/${file}`)
              .getDownloadURL()
              .then( async function(url)  {
                  resolve(url);   
              });
          } catch (error) {
              console.log(error);
              reject('')
          }
      });
    }


    return (
        <ParticipantesContext.Provider
            value={{  
                columns,
                options,
                data,
                listParticipantes,
                getParticipante,
                getImage,
                buscarImagen,
                eliminarOpen, setEliminarOpen,
                eliminarId, setEliminarId,
            }}
        >
            {props.children}
        </ParticipantesContext.Provider>
    );
}

export default ParticipantesProvider;