import React, { useEffect, useState }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import {
  AppBar,
  Button,
  makeStyles,
  Toolbar,
  Drawer,
  Hidden,
  List,
  Fab
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidthMini = 72;
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    grow: {
      flexGrow: 1,
    },
    AppLogoMenu: {
      height: '8vh',
    },
    AppLogoMenuOpen: {
      width: 'auto',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      position: 'relative',
      width: drawerWidth,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    hiddenBar: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    appBar: {
      visibility: 'visible',
      transform:'none',
      transition: 'all 200ms ease-in',
/*       position: 'fixed',
 */      background: 'transparent',
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    appBarScroll:{
      [theme.breakpoints.up('md')]: {
        visibility: 'hidden',
        transform:'translate(0, -100%)',
        transition: 'all 200ms ease-out',
      },
    },
    sectionLogo: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex', 
      },
    },
    sectionDesktop: {
      display: 'none',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        display: 'flex', 
      },
    },
    toolbar: {
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
      },
      justifyContent: 'flex-end',
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: drawerWidthMini,
      [theme.breakpoints.up('sm')]: {
        width: drawerWidthMini,
      },
    },
    menuButton: {
      margin: theme.spacing(2),
      display:'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    linkMobile: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    paper: {
      marginRight: theme.spacing(2),
      background: theme.palette.primary.main,
      '& *': { color: 'rgba(255, 255, 255, 0.7)' },
    },
    
    listMobile: {
      display: 'flex',
      flexDirection: 'column',
    },

    paperMobile:{
      [theme.breakpoints.down('sm')]: {
        display:'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
      }, 
    },
    button: {
      textTransform: 'capitalize !important',
      fontSize: '18px !important',
      height: '35px!important',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 1.2),
      textTransform: `none`,
      fontSize: `1.2em`,
      color: '#fff',
    },
    loginButton: {
        border: `1px solid  #fff`,
        padding: `1px 20px`,
        fontSize: `1.3em`,
        color: '#fff',
        margin: theme.spacing(1, 1.2),
        textTransform: `none`,
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
    
  }));
 
export default function MenuBar() {
    const classes = useStyles();
    let button;
    const history = useHistory();
    const open = false;
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);

    const menu =
      <nav  className={classes.paperMobile} >
       
      {/*   <Linkx  component={Link} to="/" className={classes.linkMobile}>
        <img src={logoAlter} component={Link}
        className={clsx(classes.AppLogoMenu, open && classes.AppLogoMenuOpen)}
        alt="Seguros Ok" />
        </Linkx> */}
        
      {/*   <Linkx variant="button" component={Link} to="/preguntas_frecuentes"  className={classes.link}>
        Preguntas Frecuentes
        </Linkx>
        <Linkx variant="button" component={Link} to="/glosario" className={classes.link}>
        Glosario
        </Linkx>
        <Linkx variant="button"  href="#Contactanos" className={classes.link} > 
        Contáctanos
        </Linkx>
        <Linkx variant="button"  component={Link} to="/trabaja_nosotros" className={classes.link}>
        Trabaja con nosotros
        </Linkx> */}
      </nav>
    
    const handleMobileDrawerOpen = () => {
      if (open === true) {
        setOpenMobile(false);
      } else {
        setOpenMobile(true);
      }
    };

    const handleDrawerToggle = () => {
      setOpenMobile(!openMobile);
    };

    function LoginButton(props) {
      return (
        <Button  variant="outlined" color="inherit" onClick={props.onClick}  component={Button} className={classes.loginButton}>
          Iniciar Sesión
        </Button>
      );
    }
    
    function AccesoButton(props) {
      return (
          <Button variant="outlined" color="inherit" onClick={props.onClick} className={classes.loginButton}>
            Ingresar a la Consola
          </Button>
      );
    }

    if (!isLoggedIn) {
      button = <LoginButton onClick={handleLogin} />;
    }else{
      button = <AccesoButton onClick={handleAcceso} className={classes.loginButton}/>;
    }
  
    function handleLogin(e) {
      e.preventDefault();
      history.push("/login"); 
    }
  
    function handleAcceso(e) {
      e.preventDefault();
      history.push("/admin"); 
    }
  
    useEffect(() => {
  
      async function consultar_sesion_usuario() {
        try {
          setIsLoggedIn(false);
        } catch (err) {
          console.log('error: ', err)
        }
      }
      consultar_sesion_usuario();
  
    }, [])
  
    return (
      <React.Fragment>
        <AppBar position="static"  elevation={0}
         className={clsx(classes.menuBar, classes.appBar,
           open && classes.appBarShift
         )}>
            <Toolbar className={classes.toolbar} >
              <div className={classes.sectionLogo}>
                {/* <Linkx  component={Link} to="/"  >
                  <img src={logo} component={Link}
                  className={clsx(classes.AppLogoMenu, open && classes.AppLogoMenuOpen)}
                  alt="Seguros Ok" />
                </Linkx> */}
              </div>
              <div className={classes.sectionDesktop}>
                
                <div className={classes.toolbarTitle} />
                {menu}
                {button}
               {/*  <Button href="#" variant="outlined" className={classes.loginButton}>
                  Iniciar sesión
                </Button> */}
              </div>
              <Fab 
                color="primary" 
                aria-label="add"
                onClick={handleMobileDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </Fab>
            </Toolbar>
        </AppBar>
        <Hidden smUp implementation="css">
          <Drawer
            //container={container}
            variant="temporary"
            //anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={openMobile}
            onClose={handleDrawerToggle}
            classes={{
              paper: clsx(classes.paper, classes.drawerPaper),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
             <List className={classes.listMobile}>
             {menu}
             {button}
             </List>
          
          </Drawer>
        </Hidden>
      </React.Fragment>
    )
}