import React, { useState, useContext, useEffect } from 'react';
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { ImageLoading } from 'components/loading';

//styles
import useStyles from './styles'

//Context
import { ParticipantesContext } from './context';
import { APIContext } from 'context';

const ParticipantesView = () => {

  const classes = useStyles();
  const { 
    setEliminarOpen,
    eliminarOpen,
    eliminarId,
    listParticipantes,
    options, 
    columns, 
    data
  } = useContext(ParticipantesContext);
  const [show, setShow ]=  useState(false);
  const { getPromocion, promocionActual, deleteUsuario} = useContext(APIContext);
 
  useEffect(() => {
      setShow(true)
  }, [data.length]);
  
 useEffect(() => {
   iniciar()
  }, []); 

  async function iniciar(){
    await getPromocion();
    listParticipantes(promocionActual && promocionActual.id)
  }

  const handleClose = () => {
    setEliminarOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Dialog
        open={eliminarOpen}
        onClose={handleClose}
        aria-labelledby="eliminar-dialog-title"
        aria-describedby="eliminar-dialog-description"
        maxWidth={'sm'}
      >
        <DialogTitle id="eliminar-dialog-title" color="primary">Eliminar Usuario</DialogTitle>
        <DialogContent>
          <DialogContentText id="eliminar-dialog-description" color="secondary">
            Desea proceder con la eliminación de este usuario?
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={()=>{
              deleteUsuario(eliminarId)
              setEliminarOpen(false)
            }} 
            color="primary" autoFocus>
            Eliminar
            </Button>
            <Button onClick={handleClose} color="secondary" autoFocus>
            Cancelar
            </Button>
        </DialogActions>
      </Dialog>
    
      <main className={classes.content}>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>
              
              {show ? (
                <MUIDataTable
                  title={"Participantes"}
                  data={data}
                  columns={columns}
                  options={options}

                />
              ) : (
                <ImageLoading />
                
              )}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default ParticipantesView;