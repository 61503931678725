import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { APIContext } from '../context'
import SuccessView from 'components/views/success'
import smarthphone_voltear from 'img/smartphone_voltear.gif';

export const EliminarDialog = ({
    open,
    id
  }) => {
    const [elmOpen, setElmOpen] = useState(false)

    useEffect(() => {
      setElmOpen(open)
    }, [open]);

  const handleClose = () => {
    setElmOpen(false)
  }
  return (
    <Dialog
      open={elmOpen}
      onClose={handleClose}
      aria-labelledby="eliminar-dialog-title"
      aria-describedby="eliminar-dialog-description"
      maxWidth={'sm'}
    >
      <DialogTitle id="eliminar-dialog-title" color="primary">Eliminar Usuario</DialogTitle>
      <DialogContent>
        <DialogContentText id="eliminar-dialog-description" color="secondary">
          Desea proceder con la eliminación de este usuario?
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
          Eliminar
          </Button>
          <Button onClick={handleClose} color="secondary" autoFocus>
          Cancelar
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export const AlertDialog = ({
  open,
  title,
  text
}) => {
  const {dialogAlertContent, dialogAlertOpen, setDialogAlertOpen} = useContext(APIContext);
  const handleClose = () => {
    setDialogAlertOpen(false);
  }
  return (
    <Dialog
      open={dialogAlertOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'sm'}
    >
      <DialogTitle id="alert-dialog-title" color="primary">{dialogAlertContent.titulo}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" color="secondary">
          {dialogAlertContent.mensaje}
        </DialogContentText>
        {dialogAlertContent.imagen === 'smarthphone_voltear' &&
        <>
            <img
              alt="smartphone voltear" 
              src={smarthphone_voltear} 
              style={{
                maxHeight: "150px",
                maxWidth: "150px",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex"}}
              />
            <Typography variant="body1" fontWeight={400}>
            Si estás en un celular, ponlo en sentido horizontal
            </Typography>
        </>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Aceptar
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export const SuccessDialog = () => {
  const {dialogSuccessOpen, setDialogSuccessOpen} = useContext(APIContext);
  const handleClose = () => {
    setDialogSuccessOpen(false);
  }
  return (
    <Dialog
      open={dialogSuccessOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'sm'}
    >
      <DialogContent>
         <SuccessView />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Aceptar
          </Button>
      </DialogActions>
    </Dialog>
  );
}