import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//styles
import useStyles from './styles'

const CotizacionSuccess = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent className={classes.cardContent}>
        <Box display="flex" justifyContent="center" flexDirection="column" p={1} >
          <Box p={1} >
            <Typography variant="h5" color="textSecondary" gutterBottom>
              Cotización Ok :
            </Typography>
          </Box>
          <Box p={1} >
            <img className={classes.img} alt="error500" />
          </Box>
          <Box p={1} >
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Se ha enviado su cotización al correo electrónico registrado.
              Un asesor asignado se pondrá en contacto en unos momentos.
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
export default CotizacionSuccess;