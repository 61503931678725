import React from "react";
import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppMenubar from './Appbar-Menubar';
import Appbar from '../components/landing/menuBar';
import Copyright from '../components/landing/copyright';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AlertDialog, SuccessDialog} from '../components/Dialog.js'

import { APIContext } from '../context'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background:'#f6f7f9eb'
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },      
  }));

export const  Adminlayout = (props) => {
    const classes = useStyles();

    //APIContext
    const {loading, dialogSuccessOpen, dialogAlertOpen} = useContext(APIContext);

    return(
    <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <AlertDialog open={dialogAlertOpen} />
        <SuccessDialog open={dialogSuccessOpen} />
        <div className={classes.root}>
            <CssBaseline />    
            <AppMenubar />
            <main  className={classes.content}>
                <div className={classes.appBarSpacer} />
                <div id="adminContent">
                    {props.children}
                </div>
                
            </main>
        </div>
    </React.Fragment>
    );
}

export const Publiclayout = (props) => {
    const classes = useStyles();
    const {loading, dialogSuccessOpen, dialogAlertOpen} = useContext(APIContext);

    return(
    <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <AlertDialog open={dialogAlertOpen} />
        <SuccessDialog open={dialogSuccessOpen} />
        <Appbar />
            {props.children}
       {/*  <Footer /> */}
        <Copyright />
    </React.Fragment>
    );
}


export const Signlayout = (props) => {
    const classes = useStyles();
    const {loading, dialogAlertOpen} = useContext(APIContext);

    return(
    <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <AlertDialog open={dialogAlertOpen} />
        {props.children}
    </React.Fragment>
    );
}

