import React, { useContext} from 'react';
import { 
  makeStyles,
  LinearProgress,
  Typography,
  Box
} from '@material-ui/core';
import { APIContext } from '../../context';
import loading from 'img/loading.svg';

function LinearProgressWithLabel(props) {
    
    return (
        <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
            <Typography variant="body2" color="textPrimary">{`${Math.round(
            props.value,
            )}%`}</Typography>
        </Box>
        </Box>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export  function LinearWithValueLabel() {
  const classes = useStyles();
  const { loading_total, loading_avance} = useContext(APIContext);
  let MIN = 1
  let MAX = loading_total

  const normalise = value => (value - 1) * 100 / (MAX - MIN);

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={normalise(loading_avance)} />
    </div>
  );
}

export function ImageLoading() {

  return (
    <div className="loading_container">
      <img src={loading} className="loading" alt="cargando..." />
    </div>
  );
}


