import React, { createContext, useState, useContext  } from 'react';
import {
  Switch,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CustomToolbar from '../../../customToolbar';
import UsuarioDialogForm from 'components/forms/usuarioForm';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { APIContext } from 'context';

export const UsuariosContext = createContext();

  const UsuariosProvider = (props) => {
    const [open, setOpen] = useState(false);
    const [eliminarOpen, setEliminarOpen] = useState(false);
    const [eliminarId, setEliminarId] = useState('');
    const [form, setForm] = useState({
        ase_nombre: "",
        password: "",
        ase_tipide: "",
        ase_numide: "",
        ase_email: "",
        ase_telefono: "",
        ase_telefono2: "",
        ase_rol: "",
        ase_estado: "",
    });

    const [editableObject, seteditableObject] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const { 
      usuarios,
      updateUsuario,
      sendPasswordEmail,
    } = useContext(APIContext);

    const headCells = [
        { id: 'id', numeric: true, disablePadding: false, label: '#' },
        { id: 'nombres', numeric: false, disablePadding: false, label: 'Nombres' },
        { id: 'apellidos', numeric: false, disablePadding: false, label: 'Apellidos' },
        { id: 'identificacion', numeric: false, disablePadding: false, label: 'Número de identificación' },
        { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
        { id: 'telefono', numeric: false, disablePadding: false, label: 'Teléfono' },
        { id: 'tipo', numeric: true, disablePadding: false, label: 'Rol' },
        { id: 'estado', numeric: true, disablePadding: false, label: 'Estado' },
        { id: 'nom_estado', numeric: true, disablePadding: false, label: 'Estado' },
    ];

    const idName = "ase_id";

    const data = usuarios

    const tipo_ide = [
        { value: 1, tipo: "Cédula" },
        { value: 2, tipo: "RUC" },
        { value: 3, tipo: "Pasaporte" }
    ]

    const estado = [
        { value: 1, tipo: "Activo" },
        { value: 2, tipo: "Inactivo" },
    ]

    const rol = [
        { value: 1, tipo: "Administrador" },
        { value: 2, tipo: "Colaborador" },
        { value: 3, tipo: "Cliente" },
    ]

    const columns = [
        //{ name: 'id', label: '#' },
        { name: 'nombres', label: 'Nombres' },
        { name: 'apellidos', label: 'Apellidos' },
        { name: 'identificacion', label: 'CI/RUC' },
        { name: 'email', label: 'Email' },
        { name: 'tipo', label: 'Rol' },
        {
          name: "estado",
          label: 'Estado',
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
                return (
                  <Switch
                    checked={data[dataIndex].estado === 1 ? true : false}
                    onChange={(event) => (handleChange(dataIndex))}
                    name="estado"
                    color="primary"
                  />
                );
            }
          }
        },
        {
          name: "id",
          label:" ",
          options: {
    
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
              const data = usuarios[tableMeta.rowIndex];
              
              return (
                <>
                <Tooltip title="Editar/Ver">
                  <IconButton
                    onClick={(e)=>{
                      e.preventDefault()
                      seteditableObject(data)
                      handleDialogOpen()
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Restablecer Contraseña">
                  <IconButton aria-label="Restablecer Contraseña"
                    onClick={(e) => {
                      sendPasswordEmail(value ? value : data.uid)
                    }}
                  >
                    <MailOutlineIcon/>
                  </IconButton>
                </Tooltip>
            
                <Tooltip title="Eliminar">
                  <IconButton
                    onClick={(e)=>{
                      e.preventDefault()
                      console.log(data.uid)
                      setEliminarId(value ? value : data.uid)
                      setEliminarOpen(true)
                    }}
                  >
                    <DeleteOutlineIcon 
                      style={{ color: '#f47373' }} 
                    />
                  </IconButton>
                </Tooltip>
                </>
              );
            }
          }
        },
        /* {
          name: "",
          options: {
    
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              const data = usuarios[dataIndex];
    
              return (
                <>
    
                  <Button variant="outlined" color="primary"
                    onClick={(e) => {
                      seteditableObject(data);
                      handleDialogOpen();
                    }}>
                    Ver
                  </Button>
    
                </>
              );
            }
          }
        } */
    
    ];
     
    const options = {
      responsive: 'standard',
      selectableRows: 'none',
        filter: true,
        filterType: "multiselect",
        textLabels: {
          body: {
            noMatch: "Lo siento, no se encuentran Registros",
            toolTip: "Sort",
            columnHeaderTooltip: column => `Ordenar por ${column.label}`
          },
          pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Registros por página:",
            displayRows: "de",
          },
          toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "ver Columnas",
            filterTable: "Filtrar Tabla",
          },
          filter: {
            all: "TODOS",
            title: "FILTROS",
            reset: "RESTABLECER",
          },
          viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Mostrar/Ocultar Columnas",
          },
          selectedRows: {
            text: "Registro(s) seleccionados",
            delete: "Eliminar",
            deleteAria: "Eliminar Registro(s) seleccionados",
          },
        },
        customToolbar: () => {
          return (
            <CustomToolbar Component={UsuarioDialogForm} CustomIcon={AddIcon} />
          );
        },
    }

    function handleChange(index){
      data[index].estado = data[index].estado === 1 ? 0 : 1
      updateUsuario(data[index])
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
      };
      const handleDialogClose = () => {
        setDialogOpen(false)
      }

    const clearForm = () => {
        setForm({
            ase_nombre: "",
            password: "",
            ase_tipide: "",
            ase_numide: "",
            ase_email: "",
            ase_telefono: "",
            ase_telefono2: "",
            ase_rol: "",
            ase_estado: "",
        })
    }

    return (
        <UsuariosContext.Provider
            value={{
                form,
                open,
                headCells,
                idName,
                columns,
                options,
                data,
                dialogOpen,
                editableObject,
                tipo_ide,
                rol,
                estado,
                eliminarOpen, setEliminarOpen,
                eliminarId, setEliminarId,
                clearForm,
                setOpen,
                handleDialogOpen,
                handleDialogClose,
            }}
        >
            {props.children}
        </UsuariosContext.Provider>
    );
}

export default UsuariosProvider;