import  firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const app = firebase.initializeApp({
    apiKey: "AIzaSyD3iHAPWZZJEugn8DZTtWNgD3bdZvZeBNs",
    authDomain: "wanabana-baby-promo.firebaseapp.com",
    databaseURL: "https://wanabana-baby-promo.firebaseio.com",
    projectId: "wanabana-baby-promo",
    storageBucket: "wanabana-baby-promo.appspot.com",
    messagingSenderId: "922700264040",
    appId: "1:922700264040:web:cb569f70e8b1c9866001dd",
    measurementId: "G-G2Z1EJ65W6"

  });

  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

  export {
    app,
    googleAuthProvider
  }