import React from 'react';
import { Link } from "react-router-dom";

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ContactsIcon from '@material-ui/icons/Contacts';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faGavel } from '@fortawesome/free-solid-svg-icons' */

export const AdministradorListItems = (
  <React.Fragment >

    <ListItem button to="/admin" component={Link}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Principal" />
    </ListItem>
  
    <ListItem button to="/admin/usuarios" component={Link}>
      <ListItemIcon>
        <PeopleAltIcon />
      </ListItemIcon>
      <ListItemText primary="Usuarios" />
    </ListItem>
    <ListItem button to="/admin/participantes" component={Link}>
      <ListItemIcon>
        <ContactsIcon />
      </ListItemIcon>
      <ListItemText primary="Participantes" />
    </ListItem> 
    <ListItem button to="/admin/promocion" component={Link}>
      <ListItemIcon>
        <CardGiftcardIcon />
      </ListItemIcon>
      <ListItemText primary="Promoción" />
    </ListItem> 
    <ListItem button to="/admin/ciudad" component={Link}>
      <ListItemIcon>
      <HomeWorkIcon />
      </ListItemIcon>
      <ListItemText primary="Ciudades" />
    </ListItem> 

  </React.Fragment>
);

export const ColaboradorListItems = (
  <React.Fragment>
    <Divider />
    <ListItem button to="/admin" component={Link}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Principal" />
    </ListItem>
    <ListItem button to="/admin/participantes" component={Link}>
      <ListItemIcon>
        <ContactsIcon />
      </ListItemIcon>
      <ListItemText primary="Participantes" />
    </ListItem> 
    <ListItem button to="/admin/ciudad" component={Link}>
      <ListItemIcon>
      <HomeWorkIcon />
      </ListItemIcon>
      <ListItemText primary="Ciudades" />
    </ListItem> 
  </React.Fragment>
);