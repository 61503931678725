import React from 'react';
import { useState, useContext, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from "mui-datatables";
import CiudadDialogForm from 'components/forms/ciudadForm';
import loading from 'img/loading.svg';

//styles
import useStyles from './styles'

//Context
import { CiudadContext } from './context';
import { APIContext } from 'context';

const CiudadView = () => {

  const classes = useStyles();
  const { row, dialogOpen, handleDialogClose, options, columns } = useContext(CiudadContext);
  const [show, setShow ]=  useState(false);
  const { ciudad, listCiudad } = useContext(APIContext);
 
  
  useEffect(() => {
    iniciar()
  }, []);

  useEffect(() => {
    if(ciudad.length > 0){
      setShow(true)
    }
  }, [ciudad.length]);

  function iniciar(){
    listCiudad();
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <main id='ciudad' className={classes.content}>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>
              {dialogOpen &&
                row &&
                <CiudadDialogForm 
                  dialogOpen={dialogOpen} 
                  handleClose={handleDialogClose} 
                  row={row} 
                  edit={true} 
                />
              }
              
              
              {show ? (
                <MUIDataTable
                  title={"Ciudad"}
                  data={ciudad}
                  columns={columns}
                  options={options}

                />
              ) : (
                <div className="loading_container">
                  <img src={loading} className="loading" alt="cargando..." />
                </div>
                
              )}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default CiudadView;