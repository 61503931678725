import React, { useContext, useEffect } from "react";
import { BrowserRouter as Routes, Route, Switch, Redirect } from 'react-router-dom';
import { useClearCache } from 'react-clear-cache';

import Home from "./view/home";
import Login from "./view/login";

import ParticipantesView from "./components/views/participantes";
import ParticipanteView from "./components/views/participante";

import PromocionView from "./components/views/promocion";
import CiudadView from "./components/views/ciudad";

import DashboardView from "./components/views/dashboard";
import UsuariosView from "./components/views/usuarios";

import {Error404} from "./components/views/error";
import { Adminlayout, Publiclayout, Signlayout } from './components/Layout';

import './App.css';

import DashboardProvider from '../src/components/views/dashboard/context';
import UsuariosProvider from '../src/components/views/usuarios/context';
import ParticipantesProvider from '../src/components/views/participantes/context';
import PromocionesProvider from './components/views/promociones/context';
import CiudadProvider from '../src/components/views/ciudad/context';


import { APIContext } from './context';

function App() {
  const { promocionActual, getPromocion, checkAuth, checkUser, authenticated } = useContext(APIContext);
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    iniciar()
  }, []); 

  async function iniciar(){
    await getPromocion();
    checkUser()
    if(!isLatestVersion){
      console.log('No es la ultima versión')
      setTimeout(function(e){
          emptyCacheStorage();
      }, 1000);
      
    }else{
      console.log('Actualizada ultima versión')
     
    }
  }

  return (
    <div className="App">
      <Routes>
        <Switch>
          <Route exact path="/" render={() =>
            <PublicContainer />
          } />
          <Route path="/(login)" render={() =>
            authenticated ? (<Redirect to="/admin" />) : (<SignContainer />)
          } />
          
          <Route path="/(admin)" render={() =>
            checkAuth() ? (<AdminContainer data={promocionActual} />) : (<Redirect to="/" />)
          } />
          <Route component={NotFound} />

        </Switch>
      </Routes>
    </div>  
  );
}

const NotFound = () => (
  <Publiclayout>
    <Error404 />
  </Publiclayout>
)

const PublicContainer = () => (
  <Publiclayout>
    <Route path="/" component={Home} />
  </Publiclayout>
)

const AdminContainer = ({data}) => (
  <Adminlayout>
    <DashboardProvider>
      <Route exact path="/admin" render={() => <DashboardView />} />
    </DashboardProvider>
    <ParticipantesProvider>
      <Route exact path="/admin/participantes" render={() => 
        data && (<ParticipantesView />) }
      />
       <Route exact path="/admin/participantes/:id" render={(props) => 
        data && ( <ParticipanteView id={props.match.params.id}/>) } 
      />
    </ParticipantesProvider>
   
    <PromocionesProvider>
      <Route exact path="/admin/promocion" render={() => <PromocionView />} />
    </PromocionesProvider>

    <CiudadProvider>
      <Route exact path="/admin/ciudad" render={() => <CiudadView />} />
    </CiudadProvider>
    
    <UsuariosProvider>
      <Route exact path="/admin/usuarios" render={() => <UsuariosView />} />
    </UsuariosProvider>

  </Adminlayout>
)

const SignContainer = () => (
  <div className="container">
    <Signlayout>
      <Route path="/login" component={Login} />
    </Signlayout>
  </div>
)

export default App;