import React,{ useEffect} from "react"
import { Link } from "react-router-dom" ;

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Linkx from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  
  copyrightContainer: {
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.paper,
    minHeight: '5vh',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  copyrightText: {
    fontWeight: 'bolder',
    fontFamily: 'gothanBold, Arial',
    color: theme.palette.background.paper,
    fontSize: '1.5rem',
  }
}));


export default function Copyright() {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0});
  }, []);
  

  return (
    <Paper className={classes.copyrightContainer} >
      <Box  display="flex" flexWrap="wrap" justifyContent="center" py={2}>
        <Box  px={2}>
          <Typography variant="body1"  align="center">
            {'Copyright © '}
            <Linkx color="inherit" component={Link}   to="/" >
              WanaBana
            </Linkx>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
        <Box  px={2}>
          <Typography variant="body1"  align="center">
            {/* <Linkx color="inherit" component={Link}   to="/terminos_condiciones" >
              Términos y Condiciones
            </Linkx> */}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}