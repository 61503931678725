import React, { useState } from 'react';
import {  Tooltip, Fab } from "@material-ui/core";

const CustomToolbar = ({ Component, CustomIcon, clearForm }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false)
        if (clearForm) {
            clearForm()
        }
    }

    return (
<>
            <Tooltip title={CustomIcon.displayName === "CloudUploadIcon" ? "Cargar archivo" : "Nuevo registro"}>
                <Fab variant="round" size="small" color="primary" elevation={0} onClick={handleDialogOpen}>
                    <CustomIcon />
                </Fab>
            </Tooltip>

            <Component dialogOpen={dialogOpen} handleClose={handleDialogClose} array={[]} />
        </>
    );
}

export default CustomToolbar;
