import React, { useContext } from 'react';
import {
    makeStyles,
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { APIContext } from 'context'
import { ParticipantesContext } from '../../participantes/context'

const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
    formControl: {
        //padding: theme.spacing(1, 2, 2 , 2),
    },
    TituloForm: {
        textTransform: 'Uppercase',
        fontWeight: '600',
        marginBottom: theme.spacing(2),
    },
    SubTituloForm:{
        marginBottom: theme.spacing(2),
    },
    Divider: {
        margin: theme.spacing(3, 0, 3, 0),
        backgroundColor: theme.palette.primary.main,
        height: '2px',
    },
    Input: {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,

    },
    InputBase: {
        textTransform: 'Uppercase',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main,
    },
    card: {
       // boxShadow: 'none',
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        padding: theme.spacing(2, 2, 2, 2),
        borderColor: '#cccecea3',
        marginBottom: theme.spacing(3),
    },
    containerButton: {
        textAlign: 'center'
    },
    buttonCalcular: {
        textTransform: 'capitalize',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.1rem',
        fontSize: '1.2rem',
        color: '#fff',
        float: 'right'
    },
    label:{
        display:'flex',
        alignItems:'center',
        width:'280px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
          },
    },
    input:{
        [theme.breakpoints.up('sm')]: {
            width: 400
        },
    },
    boxContainer:{
        [theme.breakpoints.down('sm')]: {
            flexDirection:"column",
        },
        flexDirection:"row",
    },

}));

export default function InfoCard({data, id}) {

    const classes = useStyles();
    const { promocionActual } = useContext(APIContext);
    const { buscarImagen } = useContext(ParticipantesContext);

   /*  useEffect(() => {
        if (loggedUser) {
          if ((loggedUser.ase_rol < 4)) {
            setPermiso(true);
          }
        } 
    }, [loggedUser]); */

    return (
        <>
        <Card className={classes.card} variant="outlined" >
            <CardContent className={classes.FormBase}> 
                <Box  display="flex" flexDirection="column" p={0} mt={3}>
                    <Box  mb={3}>
                        <Typography color='primary' variant='h4'>
                            Participante
                        </Typography>
                        <Typography style={{color:'gray'}} variant='body2'>
                            Id: {id}
                        </Typography>
                    </Box>
                    <Box  display="flex" className={classes.boxContainer} p={0} my={1}>
                        <Box className={classes.label} mr={4}>
                            <Typography color='primary' align='left'>
                                Nombre Completo
                            </Typography>
                        </Box>
                        <Box className={classes.input}>
                            <TextField
                                name="nom_cli"
                                value={data.nom_cli}
                                variant="outlined"
                                placeholder=" Nombre del representante Legal"
                                fullWidth 
                                size="small"
                                inputProps={{
                                    disabled: true
                                }}
                            />
                        </Box>
                    </Box>
                    <Box  display="flex" className={classes.boxContainer} p={0} my={1}>
                        <Box className={classes.label} mr={4}>
                            <Typography color='primary' align='left'>
                                CI/RUC
                            </Typography>
                        </Box>
                        <Box className={classes.input}>
                            <TextField
                                name="ced_cli"
                                value={data.ced_cli}
                                variant="outlined"
                                placeholder="099999999999"
                                //fullWidth 
                                size="small"
                                inputProps={{
                                    disabled: true
                                }}
                            />
                        </Box>
                    </Box>
                    {/* <Box  display="flex" className={classes.boxContainer} p={0} my={1}>
                        <Box className={classes.label} mr={4}>
                            <Typography  color='primary' align='left'>
                               Número de Celular
                            </Typography>
                        </Box>
                        <Box className={classes.input}>
                            <TextField
                                name="cel_cli"
                                value={data.cel_cli}
                                variant="outlined"
                                placeholder="999999999"
                                //fullWidth 
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+(593)</InputAdornment>,
                                }}
                                inputProps={{
                                    disabled: true
                                }}
                            />
                        </Box>
                    </Box> */}
                    <Box  display="flex" className={classes.boxContainer} p={0} my={1}>
                        <Box className={classes.label} mr={4}>
                            <Typography color='primary' align='left'>
                                Email
                            </Typography>
                        </Box>
                        <Box className={classes.input}>
                            <TextField
                                name="email"
                                value={data.email}
                                variant="outlined"
                                placeholder=" Ingrese su correo electrónico"
                                fullWidth 
                                size="small"
                                inputProps={{
                                    disabled: true
                                }}
                            />
                        </Box>
                    </Box>
                    <Box  display="flex" className={classes.boxContainer} p={0} my={1}>
                        <Box className={classes.label} mr={4}>
                            <Typography  color='primary' align='left'>
                                Ciudad
                            </Typography>
                        </Box>
                        <Box className={classes.input}>
                            <TextField
                                name="ciudad"
                                value={data.ciu_nombre}
                                variant="outlined"
                                placeholder="Ciudad"
                                fullWidth 
                                size="small"
                                inputProps={{
                                    disabled: true
                                }}
                            />
                        </Box>
                    </Box>
                    {/* <Box  display="flex" className={classes.boxContainer} p={0} my={1}>
                        <Box className={classes.label} mr={4}>
                            <Typography  color='primary' align='left'>
                                Número de Factura
                            </Typography>
                        </Box>
                        <Box className={classes.input}>
                            <TextField
                                name="fac_num"
                                value={data.fac_num}
                                variant="outlined"
                                placeholder="001-001-0000001"
                                fullWidth 
                                size="small"
                                inputProps={{
                                    disabled: true
                                }}
                            />
                        </Box>
                    </Box> */}
                    <Box  display="flex" className={classes.boxContainer} p={0} my={1}>
                        <Box className={classes.label} mr={4}>
                            <Typography  color='primary' align='left'>
                                Número de Factura
                            </Typography>
                        </Box>
                        <Box className={classes.input}>
                            <TextField
                                name="fac_num"
                                value={new Date(data.fecha.seconds*1000).toLocaleDateString()}
                                variant="outlined"
                                placeholder="001-001-0000001"
                                fullWidth 
                                size="small"
                                inputProps={{
                                    disabled: true
                                }}
                            />
                        </Box>
                    </Box>
                    <Box  display="flex" className={classes.boxContainer} p={0} my={1}>
                        <Box className={classes.label} mr={4} />
                        <Box className={classes.input}>
                            <Button 
                                variant="outlined" 
                                color="primary" 
                                startIcon={ 
                                    <FontAwesomeIcon icon={['fas', 'image']} size="2x" /> 
                                }
                                onClick={()=>(
                                    buscarImagen(promocionActual.id,`${data.ced_cli}-${data.fac_num}-${id}`)
                                )}
                                >
                               Foto de la Factura
                            </Button>
                        </Box>
                    </Box>
                    <Box  display="flex" flexDirection="row" p={0} my={1}>
                        <Box className={classes.label} mr={4} />
                        <Box mr={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        //value="allowExtraEmails"
                                        checked={data.acepto_terminos} 
                                        color="primary"
                                        
                                    />
                                }
                                label="Acepto términos y condiciones"
                            />
                        </Box>
                    </Box>
                   
                </Box>
               
                
            </CardContent>
        </Card>
        </>
    )
}


