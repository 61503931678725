import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  buttonExport:{
    backgroundColor:"inherit",
    borderRadius:"6px",
    border:"1px solid",
    borderColor: theme.palette.primary.main,
    display:"inline-block",
    cursor:"pointer","color":"#ffffff",
    color:theme.palette.primary.main,
    fontSize:"14px",
    fontWeight:"bold",
    padding:"6px 24px",
    textDecoration:"none",
    marginLeft:'1em',
    marginRight:'1em'
  }
}));

export default useStyles;

